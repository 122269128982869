<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.fullname"
              :label="t('users.fullname')"
              dense
              outlined
              readonly
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.email"
              :label="t('users.email')"
              dense
              outlined
              readonly
              :rules="[required,emailValidator]"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.country"
              :label="t('users.country')"
              dense
              outlined
              readonly
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.phone_number"
              :label="t('users.phone')"
              dense
              outlined
              readonly
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option!==3"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.register_date"
              :label="t('users.register_date')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.last_login_date"
              :label="t('users.last_login')"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="option!==3"
            :info-required="false"
            :no-cancel="true"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onSave="onSaveAction"
            @onNextTab="changeTab"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiMenuDown } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { info } from '@core/utils/toasted'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const form = ref(null)

    const { t } = useUtils()

    const dataComputed = computed(() => props.dataParams)

    const urlImage = ref(null)
    const imageData = ref(null)

    const statusOptions = [
      { text: 'Active', value: 'A' },
      { text: 'Inactive', value: 'I' },
    ]

    const initialData = {
      avatar: null,
      fullname: null,
      username: null,
      email: null,
      phone_number: null,
      status: 'A',
      country: null,
    }

    const cleanImage = () => {
      imageData.value = null
      urlImage.value = null
    }

    const onFileSelected = e => {
      try {
        cleanImage()

        const type = e.type.split('/').pop()
        if (e && (type === 'jpeg' || type === 'jpg' || type === 'png')) {
          imageData.value = e
          if (e) {
            urlImage.value = URL.createObjectURL(imageData.value)
          }
        } else {
          cleanImage()
        }
      } catch (err) {
        console.error('error', err)
      }
    }

    const onUpdateAvatar = async () => {
      if (imageData.value) {
        emit('updateAvatar', imageData.value)
      } else {
        info('Seleccione una imagen!')
      }
    }

    const onCancelButton = () => {
      cleanImage()
      form.value.reset()
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('save', dataComputed.value, imageData.value)
      }
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value, imageData.value)
      }
    }

    return {
      // data
      form,
      urlImage,
      imageData,
      statusOptions,
      initialData,

      // computed
      dataComputed,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      avatarText,
      onFileSelected,
      cleanImage,
      onUpdateAvatar,

      // validations
      required,
      emailValidator,

      // i18n
      t,

      // icons
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiCamera,
        mdiMenuDown,
      },
    }
  },
}
</script>
