<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :data-params="userAppData"
        ></user-bio-panel>
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-card
          id="user-form"
          min-height="350"
        >
          <!-- actions -->
          <v-fab-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  dark
                  absolute
                  small
                  top
                  right
                  fab
                  class="mb-4 me-3"
                  v-on="on"
                  @click="goBack"
                >
                  <v-icon>
                    {{ icons.mdiTableArrowLeft }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t("tooltip.back_table") }}</span>
            </v-tooltip>
          </v-fab-transition>

          <!-- tabs -->
          <v-tabs
            v-model="tab"
            show-arrows
            color="secondary"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.icon"
              color="secondary"
              :disabled="tab.disabled"
            >
              <v-icon
                v-if="!tab.hide"
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ t(`users.${tab.title}`) }}</span>
            </v-tab>
          </v-tabs>

          <!-- tabs item -->
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <user-app-tab-general
                :data-params="userAppData"
                :tab="tab"
                :option="option"
                @save="save"
                @updateAvatar="updateAvatar"
                @changeTab="changeTab"
              ></user-app-tab-general>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success, info } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import { createUserApp, editUserApp, editUserAppAvatar } from '@api'

// demos
import UserAppTabGeneral from './UserAppTabGeneral.vue'
import UserBioPanel from './UserBioPanel.vue'

export default {
  components: {
    UserAppTabGeneral,
    UserBioPanel,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const tab = ref(0)
    const option = ref(0)
    const dataId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const userAppData = ref({})
    const postData = ref({
      avatar: null,
      fullname: null,
      username: null,
      email: null,
      country: null,
      phone_number: null,
      status: 'A',
    })
    const putData = ref({
      avatar: null,
      fullname: null,
      username: null,
      email: null,
      country: null,
      phone_number: null,
      status: 'A',
    })
    const dataChange = ref(null)

    // tabs
    const tabs = ref([
      {
        title: 'overview',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm, data) => {
      dataChange.value = data
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const updateAvatar = async image => {
      const formData = new FormData()
      if (image && dataId.value) {
        formData.append('file', image)
        const responseLogo = await editUserAppAvatar(formData, dataId.value)

        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const save = async (data, imageLogo) => {
      let resp = null
      if (option.value === 1) {
        if (!imageLogo) {
          info('Seleccione una imagen!')

          return
        }
        if (data && imageLogo) {
          postData.value = Object.assign(postData.value, {
            fullname: data.fullname,
            username: data.username,
            email: data.email,
            country: data.country,
            phone_number: data.phone_number,
            avatar: data.avatar ? data.avatar : null,
            status: data.status,
          })

          resp = await createUserApp(postData.value)

          if (resp.ok) {
            dataId.value = resp.data.id
            await updateAvatar(imageLogo)
          }
        }
      }

      if (option.value === 3) {
        // putData.value = Object.assign(putData.value, {
        //   fullname: data.fullname,
        //   username: data.username,
        //   email: data.email,
        //   country: data.country,
        //   phone_number: data.phone_number,
        //   avatar: data.avatar ? data.avatar : null,
        //   status: data.status,
        // })
        const put = {
          status: data.status,
        }

        resp = await editUserApp(put, dataId.value)

        if (imageLogo) {
          await updateAvatar(imageLogo)
        }
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      userAppData.value = {}
    }

    const goBack = () => {
      router.replace({
        name: 'views-user-app-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      if (!option.value) goBack()

      if (option.value && option.value !== 1) {
        dataId.value = route.value.params.id
        userAppData.value = route.value.params.dataParams
        if (!userAppData.value) goBack()
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }
    })

    return {
      tab,
      tabs,
      option,
      dataId,
      userAppData,
      postData,
      putData,
      dataChange,

      changeTab,
      save,
      cancelAction,
      updateAvatar,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
