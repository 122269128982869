import { ref, watch, computed } from '@vue/composition-api'
import { getUsersApp, getUsersAppPaged } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useFilters from '@core/utils/useFilters'
import fetchPaged from '@core/utils/fetchPaged'

export default function useUsersAppList() {
  const userAppListTable = ref([])
  const { t } = useUtils()
  const { hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()

  // { text: t('users.name').toUpperCase(), value: 'username', show: true },
  const tableColumns = computed(() => [
    { text: t('users.fullname').toUpperCase(), value: 'fullname', show: true },
    { text: t('users.email').toUpperCase(), value: 'email', show: true },
    { text: t('users.phone').toUpperCase(), value: 'phone_number', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasPermission(24) || hasPermission(25),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))
  const totalUsersAppListTable = ref(0)

  const allowFetch = ref(false)
  const searchQuery = ref('')
  const loading = ref(false)
  const options = ref({
    sortBy: ['fullname'],
    sortDesc: [true],
  })
  const descSort = ref([])
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)

  const fetchUsersApp = async () => {
    const responseUserApp = await getUsersApp()

    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    filters.value = filterOption

    const filteredData = responseUserApp.data.filter(
      user =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        ((user.username && user.username.toLowerCase().includes(searchQuery.value)) ||
          (user.email && user.email.toLowerCase().includes(searchQuery.value)) ||
          (user.fullname && user.fullname.toLowerCase().includes(searchQuery.value)) ||
          (user.phone_number && user.phone_number.toLowerCase().includes(searchQuery.value))),
    )
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    userAppListTable.value = filteredData.map(e => ({ ...e }))
    totalUsersAppListTable.value = filteredData.length
    loading.value = false
  }

  const fetchPagedUsersApp = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    filters.value = filterOption
    const response = await fetchPaged(getUsersAppPaged, options.value, filterOption)
    userAppListTable.value = response.data.data
    totalUsersAppListTable.value = response.data.total

    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      // if (allowFetch.value) await fetchUsersApp()
      if (allowFetch.value) await fetchPagedUsersApp()
    }, 1000)
  }

  watch([searchQuery, allowFetch], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    updateFilter('views-user-app-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true

      // if (allowFetch.value) await fetchUsersApp()
      if (allowFetch.value) await fetchPagedUsersApp()
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserAppStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveUserAppStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    userAppListTable,
    computedTableColumns,
    searchQuery,
    totalUsersAppListTable,
    loading,
    options,
    filters,
    descSort,
    allowFetch,

    fetchUsersApp,
    fetchPagedUsersApp,
    resolveUserAppStatusVariant,
    resolveUserAppStatusText,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
